.container{
    margin: 0;
    color: #ebebeb;
    background: rgb(150, 115, 69) url('https://res.cloudinary.com/benjee/image/upload/v1597275028/XKBE2071-removebg_fi3ssn.png');
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    align-items: flex-end;
}
.landingpage-content{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 3rem;
}
.landingpage-text h1{
    font-family: 'Sniglet', cursive;
    font-size: 4rem;
    letter-spacing: 0.4rem;
    font-weight: 400;
}
.menu{
    display: flex;
    flex-direction: row-reverse;
    /* height: 4rem; */
    /* margin: 1rem 4rem; */
    /* background-color: aquamarine; */
}
.menuitem{
    display: flex;
    flex-direction: column;
    margin: 0rem 1rem;
    padding: 2rem;
    height: 25rem;
    width: 22rem;
    text-align: center;
    border-radius: 1rem;
    background: rgb(78,124,163);
background: linear-gradient(177deg, rgba(78, 123, 163, 0.39) 0%, rgba(21, 28, 36, 0.37) 100%);

    cursor: pointer;
}
.menuitem span{
    background-color: aqua;
    display: block;
    height: 10rem;
}
a{
    text-decoration: none;
}
.menuitem h3{
    color: #ffffff;
}
.bgImage{
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
}
.welcome_text{
    background-color: rgba(0, 0, 0, 0.267);
    padding: 0rem 8rem;
    text-align: center;
    position: absolute;
    top: 40%;
    left: 50%;
    font-size: 3rem;
    color: #fff;
    font-family: 'Sniglet', cursive;
    transform: translate(-50%,-10%);
}
.welcome_text h1{
    font-weight: 400;
    margin-bottom: 0;
}
.welcome_text p{
    font-size: 1.8rem;
}
#spiderman,#groot,#ironman,#marvel,#superman,#supermanlogo,#thor,#antman{
    position: absolute;
    transition: all .2s ease-in;
    cursor: pointer;
}
#spiderman:hover,#groot:hover,#ironman:hover,#marvel:hover,#superman:hover,#supermanlogo:hover,#thor:hover,#antman:hover{
    transform: scale(1.2);
}
#spiderman{
    bottom: 2rem;
    left: 4rem;
}
#groot{
    top: 8rem;
    left: 8rem;
}
#ironman{
    bottom: 35rem;
    left: 20rem;
}
#marvel{
    top: 4rem;
    right: 6rem;
}
#superman{
    top: 8rem;
    right: 40rem;
}
#supermanlogo{
    bottom: 2rem;
    right: 10rem;
}
#thor{
    bottom: 10rem;
    right: 60rem;
}
#antman{
    right: 20rem;
    top: 30rem;
}
.slider-wrapper{
    background-color: #fcfcfc;
    height: 100vh;
    margin: 0;
}
.ReactGridGallery_tile{
    margin: 1rem 3rem !important;
}
.ReactGridGallery_tile-viewport{
    border-radius: 1rem;
}
.nav{
    display: flex;
    padding: 2.5rem 3rem;
    justify-content: space-between;
    background-color: rgb(150, 115, 69);
    position: sticky;
    top: 0;
    z-index: 467;
    /* border-radius: 0rem 0rem 3rem 3rem; */
  }
.ReactGridGallery_tile-icon-bar{
    display: none;
}
  .image_1swebtw-o_O-imageLoaded_zgbg08{
      z-index: 56643674374587;
  }
.gallery_showcase{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.ReactGridGallery_tile{
    background: none !important;
}
.slick-slide{
    outline: none;
    cursor: pointer;
}
.slick-slide-image{
    outline: none;
    padding: 1rem 1rem;
    border-radius: 1rem;
}
.slick-slide img{
    width: auto;
}
#showcaseimage{
    width: 90rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
  .App{
      /* padding: 2rem; */
      margin: 0;
  }
  .grow{
      transition: all 0.5s ease-out;
  }
  .grow:hover{
      transform: scale(1.05);
      z-index: 12233;
  }
  .back{
      color: #000;
      font-weight: 600;
      display: flex;
      align-items: center;
  }
  
  .playground{
      margin: 0;
      background: #fff;
      color: #111;
      /* overflow: hidden; */
  }
  .playground-header{
      text-align: center;
      padding: 4rem;
      /* filter: blur(5px); */
  }
  .playground-header h1{
      font-size: 3.5rem;
  }
  .playground-header p{
      font-size: 2rem;
  }
  .playground-body{
    /* filter: blur(5px); */
      padding: 0 1rem;
      display: flex;

      justify-content: space-around;
  }
  .card{
      background-color: rgba(72, 128, 212, 0.733);
      padding: 3rem 1.5rem;
      text-align: center;
      height: 32rem;
      margin: 1rem 0rem;
      width: 230px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 1rem;
      box-shadow: 0.5rem 0.5rem 0.8rem rgba(0, 0, 0, 0.267);
  }
  .card:nth-child(2){
    background-color: rgba(165, 139, 52, 0.733);
  }
  .card:last-child{
    background-color: rgba(97, 173, 73, 0.733);
  }
  .card img{
      width: 15rem;
      border-radius: 1rem;
      background-color: rgba(72, 128, 212, 0.219);
      padding: 1rem;
  }
  .card:nth-child(2) img{
    background-color: rgba(165, 139, 52, 0.219);
  }
  .card:last-child img{
    background-color: rgba(97, 173, 73, 0.219)
  }
  .card h2{
      margin-bottom: 0;
      font-size: 1.8rem;
      cursor: default;
  }
  .card p {
      cursor: pointer;
      font-size: 1.6rem;
  }
  .modal{
      display: none;
      flex-direction: column;
      position: absolute;
        top: 48%;
        left: 50%;
        background: rgba(72, 128, 212, 0.733);
        width: 70rem;
        height: 40rem;
        color: #fafafa;
        transform: translate(-50%,-50%);
        border-radius: 1.4rem;
        padding: 1rem;
        z-index: 7656467547685769;
  }
  .modal-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .modal-header span{
      cursor: pointer;
      font-size: 2.2rem;
      padding-right: 1rem;
      font-weight: 600;
  }
  .modal-body {
      display: flex;
      justify-content: space-evenly;
      /* height: 480px; */
      margin-top: 1.5rem;
      margin-bottom: 3rem;
      font-size: 1.4rem;
      padding-top: 1rem;
  }
  .message-box{
      padding-top: 2rem;
  }

.first-number,.second-number,.third-number{
    font-size: 1.8rem;
    font-weight: 800;
    padding: 2.5rem 3rem;
    border-radius: 50%;
    cursor: pointer;
    background-color: rgba(145, 145, 145, 0.404);
}
.first-number:hover,.second-number:hover,.third-number:hover{
    background-color: rgba(85, 85, 85, 0.753);
}
#message {
    color: #000;
    text-align: center;
      font-size: 2rem;
}
.message{
    text-align: center;
      font-size: 2rem;
}
.anagram{
        display: none;
      flex-direction: column;
      position: absolute;
        top: 48%;
        left: 50%;
        background-color: rgba(97, 173, 73, 0.733);
        width: 70rem;
        height: 30rem;
        color: #fff;
        transform: translate(-50%,-50%);
        border-radius: 1.4rem;
        padding: 1rem;
        z-index: 7656467547685769;
}
#userinput{
    outline: none;
    padding: 0.4rem 1rem;
}
.btn{
    padding: 0.4rem 1rem;
}
#tryagain,#success{
    cursor: pointer;
    color: #eaeaea;
    display: none;
    font-size: 2rem;
    text-align: center;
}
@media(max-width: 766px){
    html{
      font-size: 40%;
    }
    .container{
        background: rgb(150, 115, 69);
        display: grid;
        grid-template-columns: 1fr;
    }
    .welcome_text{
        background-color: rgba(0, 0, 0, 0.267);
        padding: 0rem 9rem;
        text-align: center;
        position: relative;
        font-size: 3rem;
        transform: translate(-50%,-100%);
    }
    .playground-body{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
      }
    .welcome_text p{
        font-size: 3rem;
    }
    #spiderman,#groot,#ironman,#marvel,#superman,#supermanlogo,#thor,#antman{
        width: 10rem;
    }
    #spiderman{
        bottom: 2rem;
        left: 4rem;
    }
    #groot{
        top: 25rem;
        left: 8rem;
    }
    #ironman{
        bottom: 25rem;
        left: 20rem;
    }
    #marvel{
        top: 10rem;
        right: 6rem;
    }
    #superman{
        top: 2rem;
        right: 30rem;
    }
    #supermanlogo{
        bottom: 15rem;
        right: 10rem;
    }
    #thor{
        display: none;
    }
    #antman{
        right: 12rem;
        top: 30rem;
    }#imgg{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr ;
    }
    .anagram{
        top: 50%;
        left: 53.5%;
        width: 40rem;
        height: 30rem;
    }
    .modal{
        top: 50%;
        left: 50%;
        width: 50rem;
        height: 40rem;
    }
    .playground-body{
        flex-direction: column;
        align-items: center;
    }
    .container{
        align-items: center;
    }
    .landingpage-content{
        flex-direction: column;
        align-items: center;
    }
    .menu{
        margin-top: 8rem;
    }
  }
  @media(max-width: 450px){
    #imgg{
        grid-template-columns: 1fr 1fr;
    }
  }
  @media(max-width: 320px){
    html{
      font-size: 35%;
    }
  }
  @media(max-width: 280px){
    #imgg{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  }